@import "../../../../bootstrap-theme.scss";

.Company-Step {
  width: 100%;
  max-width: 460px;

  &__Optional-Field-Label {
    font-size: 11px;
    color: $xc-typo-disabled;
  }
}
