@import "../../../../bootstrap-theme.scss";

.Xchange-Bill-Overview {
  &__Documents {
    max-height: 250px;
    overflow-y: auto;
    border-radius: $border-radius / 2;
    border: 1px solid $border-color;
    padding: map-get($spacers, 2);
  }
}
