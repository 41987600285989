.fade-in {
  animation: fade-in 0.35s linear;
}

.second-half-fade-in {
  animation: second-half-fade-in 1.1s linear;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes second-half-fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flash-border {
  50% {
    border-color: $xc-brand-secondary-default;
    box-shadow: 0px 0px 10px -2px $xc-brand-secondary-default;
  }
}
