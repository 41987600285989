@import "./xc-colors.scss";

$font-family-base: "Manrope", sans-serif;
$primary: $xc-brand-primary-dark;
$secondary: $xc-material-surface-primary-default;
$light: $xc-material-primary-background;

$info: $xc-brand-primary-tint-12a;
$success: $xc-state-success-tint-12a;
$warning: $xc-state-warning-tint-12a;
$danger: $xc-state-error-tint-12a;

$text-muted: $xc-typo-disabled;

$form-feedback-invalid-color: $xc-state-error-dark;
$body-bg: $xc-material-primary-background;
$headings-color: $xc-typo-primary;

$font-size-base: 0.88rem;
$font-weight-normal: 500;

$breadcrumb-font-size: 12px;
$breadcrumb-font-weight: 600;
$breadcrumb-divider: ">";
$breadcrumb-divider-color: $xc-typo-reverse-secondary;
$breadcrumb-active-color: $xc-brand-secondary-light;
$form-label-font-size: 11px;
$border-radius: 16px;
$input-border-radius: 8px;
$btn-border-radius: 8px;
$btn-border-radius-sm: 8px;
$border-color: $xc-material-border;

$btn-hover-bg-shade-amount: 20%;
$btn-hover-bg-tint-amount: 0%;
$btn-active-bg-shade-amount: 5%;

$table-cell-padding-y: 1rem;
$table-cell-padding-x: 1rem;
$table-bg: $light;
$table-accent-bg: $light;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
  xxxxl: 1800px,
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer * 0.75,
  4: $spacer,
  5: $spacer * 1.5,
  6: $spacer * 2,
  7: $spacer * 3,
  8: $spacer * 4,
  9: $spacer * 5,
);

// Card
$card-border-width: 2px;
$card-border-color: $xc-material-border;
$card-cap-bg: $light;
$card-cap-padding-y: $spacer;

// Input / button
$input-btn-padding-y: map-get($spacers, 2);
$input-btn-padding-x: $spacer;
$input-bg: $light;
$input-border-color: $xc-material-border;
$input-placeholder-font-weight: 600;
$input-placeholder-color: #111111;

$input-disabled-color: $xc-typo-disabled;
$input-disabled-bg: $xc-material-surface-primary-alternative;

$form-check-input-border: 1px solid $xc-brand-secondary-default;
$form-check-input-checked-bg-color: $xc-brand-secondary-default;

// Switch
$form-switch-width: 50px;
$form-switch-padding-start: 3px;
$form-switch-color: $xc-material-primary-background;
$form-switch-checked-color: $xc-material-primary-background;

// Image
$thumbnail-border-width: 0px;

// Modals
$modal-inner-padding: map-get($spacers, 5);
$modal-content-border-color: $border-color;
$modal-content-border-radius: $border-radius;
$modal-footer-bg: $secondary;

// Badges
$badge-font-size: 12px;
$badge-font-weight: 600;
$badge-border-radius: 4px;
$badge-padding-y: 0.45em;

// Alerts
$alert-border-radius: $btn-border-radius;
$alert-padding-y: map-get($spacers, 3);

// Dropdown
$dropdown-border-radius: 0px;
$dropdown-padding-y: 0;
$dropdown-item-padding-y: map-get($spacers, 3);
$dropdown-link-color: $xc-typo-secondary;
$dropdown-link-hover-bg: $xc-material-surface-primary-alternative;
$dropdown-link-hover-color: $xc-typo-secondary;
$dropdown-link-active-bg: $xc-material-surface-primary-alternative;
$dropdown-link-active-color: $xc-typo-secondary;

$grid-gutter-width: 4em;

$placeholder-opacity-max: 0.15;
$placeholder-opacity-min: 0.075;

$progress-height: 20px;
$progress-font-size: 12px;
$progress-bg: $xc-material-surface-primary-default;
$progress-bar-color: $xc-typo-primary;

$hr-color: $xc-material-border;
$hr-opacity: 1;

@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

// Modals
$modal-footer-border-color: $gray-200;

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @if $color == "light" {
      @include button-outline-variant(
        $color: $gray-900,
        $color-hover: $color,
        $active-background: $secondary,
        $active-border: $border-color
      );
      background-color: $light;
      border-color: $border-color;
      &:disabled {
        background-color: transparent;
        border-color: $border-color;
      }
    } @else {
      @include button-outline-variant($value);
    }
  }
}
