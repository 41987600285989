@import "../../bootstrap-theme.scss";

.Info-Snippet {
  display: flex;
  background-color: $xc-brand-secondary-tint-4a;
  color: $xc-brand-primary-dark;
  border: 2px solid $xc-brand-secondary-tint-12a;
  border-radius: $input-border-radius;
  padding: map-get($spacers, 4);

  &__Icon {
    font-size: 24px;
    color: $xc-brand-secondary-default;
    background-color: $xc-brand-secondary-tint-12a;
    width: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: $input-border-radius;
    margin-right: map-get($spacers, 4);
  }

  &__Close-Button {
    &:hover {
      cursor: pointer;
    }
  }

  ul {
    padding-left: map-get($spacers, 4);
  }
}
