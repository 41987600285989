@import "../../bootstrap-theme.scss";

.Person-Card {
  background-color: $xc-material-primary-background;
  box-shadow: 0px 0px 0px 1px $border-color;
  border-radius: $input-border-radius;
  padding: map-get($spacers, 2);
  padding-left: map-get($spacers, 4);
  margin-top: map-get($spacers, 3);
  margin-bottom: map-get($spacers, 3);

  &--disabled {
    background-color: $xc-material-surface-primary-alternative;
  }

  &__Name {
    font-size: 14px;
    font-weight: 600;
  }

  &__Title {
    color: $xc-typo-disabled;
    font-size: 14px;
    font-weight: 500;
    margin-left: map-get($spacers, 1);
  }

  &__Actions {
    flex-shrink: 0;

    &__Close {
      background-color: $xc-material-surface-primary-default;
      color: $xc-typo-disabled;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: $input-border-radius / 2;

      &:hover {
        background-color: $xc-material-surface-primary-hover;
        color: $xc-typo-secondary;
        cursor: pointer;
      }
    }
  }
}
