@import "../../bootstrap-theme.scss";

.Image-Checkbox {
  box-shadow: 0px 0px 0px 1px $border-color;
  border-radius: $btn-border-radius;
  display: inline-block;
  background-color: $xc-material-surface-primary-alternative;

  &:hover {
    background-color: $xc-material-secondary-surface-default;
    cursor: pointer;
    box-shadow: 0px 0px 0px 2px $xc-material-secondary-surface-hover;
  }

  &__Image {
    border-radius: $btn-border-radius;
    opacity: 0.75;
  }

  &__Button {
    background-color: $xc-brand-secondary-tint-12a;
    border: 0;
    font-size: 14px;
    font-weight: 600;
    color: $xc-typo-primary;

    &:hover {
      background-color: $xc-brand-secondary-tint-12a !important;
      color: $xc-typo-primary !important;
    }

    &__Icon {
      font-size: 24px;
    }
  }

  &--selected {
    box-shadow: 0px 0px 0px 2px $xc-brand-secondary-default;
    background-color: $xc-material-secondary-surface-default;

    &:hover {
      box-shadow: 0px 0px 0px 2px $xc-brand-secondary-default;
    }

    > .Image-Checkbox__Image {
      opacity: 1;
    }

    > .Image-Checkbox__Button {
      background-color: $xc-brand-secondary-default;
      color: $xc-typo-reverse-primary;

      &:hover {
        color: $xc-typo-reverse-primary !important;
        background-color: $xc-brand-secondary-default !important;
      }
    }
  }
}
