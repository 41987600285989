@import "../../../bootstrap-theme.scss";

.Overview-Item {
  background-color: $xc-material-surface-primary-alternative;
  border-radius: 8px;
  &__Index {
    line-height: 14px;
    font-size: 14px;
    background-color: $xc-material-surface-primary-default;
    border-radius: 8px;
  }
  &__Status {
    &--complete {
      color: $xc-state-success-default;
    }
  }
}
