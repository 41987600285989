@import "../../bootstrap-theme.scss";

.Gallery-Page {
  width: 100%;
  height: 100%;

  &__Left {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    padding-left: map-get($spacers, 5);
    padding-right: map-get($spacers, 5);
    padding-bottom: map-get($spacers, 5);

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 100%;

      &--auto-min-height {
        height: auto;
      }
    }
  }

  &__Header {
    display: flex;
    align-items: self-start;
    position: fixed;
    padding-top: map-get($spacers, 5);
    padding-bottom: map-get($spacers, 5);
    width: calc(50% - (map-get($spacers, 5) * 2));
    background-color: $xc-material-primary-background;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: calc(100% - (map-get($spacers, 5) * 2));
    }

    &__Logo {
      width: 48px;
    }

    &__Content {
      flex: 1;
    }
  }

  &__Content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: calc(48px + (map-get($spacers, 5) * 2));

    h3 {
      font-weight: 800;
      font-size: 32px;
      line-height: 44px;
      margin-bottom: map-get($spacers, 6);
    }

    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 36px;
      color: $xc-typo-secondary;
      margin-bottom: map-get($spacers, 6);
    }
  }

  &__Gallery {
    position: fixed;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    border-radius: $border-radius;
    overflow: hidden;
    min-height: 500px;
    padding: map-get($spacers, 5);

    &__Overlay {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;

      &__Image {
        height: 100%;

        filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
          saturate(7500%) hue-rotate(35deg) brightness(115%) contrast(110%)
          opacity(0.1);
      }
    }

    &__Background {
      background-color: $xc-material-surface-primary-default;
      border-radius: $border-radius;
      width: 100%;
      height: 100%;
    }

    &__Content {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $border-radius;

      &__Video-Overlay {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &__Play-Button {
          width: 122px;
          line-height: 122px;
          text-align: center;
          border-radius: 122px;
          background-color: rgba($xc-material-surface-primary-default, 0.75);
          color: $xc-brand-primary-dark;
          font-size: 40px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      position: static;
      width: 100%;
      margin-top: map-get($spacers, 5);
      padding-bottom: map-get($spacers, 5);
    }

    &--auto-hide {
      @media (max-width: map-get($grid-breakpoints, lg)) {
        display: none;
      }
    }
  }

  a {
    color: $xc-brand-secondary-default;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
