@import "../../../bootstrap-theme.scss";

.Product-List-Page {
  &__Header {
    &__Filters {
      > div,
      > input {
        flex: 1;
      }

      &__Search {
        border-left: 1px solid $border-color;
      }
    }
  }

  &__List {
    height: 1000px !important;
    overflow-x: hidden;

    @for $i from 0 through 5 {
      @media (max-height: calc(1325px - calc(130px * calc(#{$i} - 1)))) {
        height: calc(1000px - 130px * #{$i}) !important;
      }
    }
  }
}
