@import "../../../bootstrap-theme.scss";

.Document-List {
  background-color: $xc-material-surface-primary-alternative;
  padding: map-get($spacers, 2);
  margin-top: map-get($spacers, 4);
  margin-bottom: map-get($spacers, 4);
  margin-left: map-get($spacers, 6) * -1;
  width: calc(100% + map-get($spacers, 8));
  border-top: 1px solid $xc-material-border;
  border-bottom: 1px solid $xc-material-border;
  max-height: 230px;
  overflow-y: auto;

  &__Item {
    background-color: $xc-material-primary-background;
    border-radius: $btn-border-radius;
    padding-top: map-get($spacers, 3);
    padding-right: map-get($spacers, 6);
    padding-bottom: map-get($spacers, 3);
    padding-left: map-get($spacers, 6);
    display: flex;
    align-items: center;

    &__Icon {
      height: 30px;
      width: 32px;
      background-color: $xc-brand-secondary-default;
      color: $xc-brand-secondary-tint-12a;
      border-radius: $btn-border-radius / 2;
      margin-right: map-get($spacers, 3);

      display: flex;
      align-items: center;
      justify-content: center;

      i {
        opacity: 0.6;
      }
    }

    &__Title {
      font-size: 12px;
      font-weight: 600;
      color: $xc-typo-disabled;
    }
  }
}
