@import "../../../../bootstrap-theme.scss";

.Contract-Signee-List {
  margin-top: map-get($spacers, 1);
  margin-bottom: map-get($spacers, 3);

  &--subject {
    border: 1px solid $border-color;
    border-radius: calc($border-radius / 2);
    padding: map-get($spacers, 4);
  }

  &--with-documents {
    padding-bottom: 0;
  }

  &__Title {
    font-weight: 600;

    &__Subtitle {
      color: $xc-typo-disabled;
      margin-left: map-get($spacers, 1);
    }
  }

  &__Actions {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  &__Documents {
    margin-left: calc(map-get($spacers, 4) * -1);
    width: calc(100% + map-get($spacers, 6));
    padding: map-get($spacers, 4);
    background-color: $xc-material-surface-primary-alternative;
    border-top: 1px solid $border-color;
    border-bottom-left-radius: $border-radius / 2 !important;
    border-bottom-right-radius: $border-radius / 2 !important;
  }
}
