@import "../../bootstrap-theme.scss";

.Progress-Board {
  border: 1px solid $border-color;
  border-radius: $border-radius;
  padding: map-get($spacers, 4);

  &__Step {
    display: flex;
    align-items: center;

    &__Icon {
      width: 56px;
      line-height: 56px;
      text-align: center;
      background-color: $xc-brand-secondary-tint-12a;
      border-radius: calc($border-radius / 4);
      margin-right: map-get($spacers, 4);
      font-size: 24px;
      color: $xc-brand-secondary-default;
    }

    &__Info {
      &__Title {
        font-size: 15px;
        font-weight: 800;
      }

      &__Description {
        font-size: 12px;
        font-weight: 600;
        color: $xc-typo-disabled;
      }
    }

    &__Status {
      i {
        font-size: 24px;
      }
    }

    &--completed {
      .Progress-Board__Step__Icon {
        color: $xc-brand-secondary-tint-30a;
        background-color: $xc-brand-secondary-tint-4a;
      }

      .Progress-Board__Step__Info__Title {
        color: $xc-typo-disabled;
      }

      .Progress-Board__Step__Status {
        i {
          color: $xc-state-success-default;
        }
      }
    }

    &:not(:last-child) {
      padding-bottom: map-get($spacers, 4);
      border-bottom: 1px solid $border-color;
      margin-bottom: map-get($spacers, 4);
    }
  }
}
