@import "../../bootstrap-theme.scss";

.Info-Header {
  background-color: $xc-brand-primary-dark;
  color: $xc-typo-reverse-primary;
  border-radius: calc($border-radius / 2);
  padding: map-get($spacers, 4);

  &__Title {
    display: inline-block;
    font-weight: 800;
    font-size: 24px;
    margin-bottom: map-get($spacers, 4);
  }

  &__Basic-Info {
    &__Label {
      display: inline-block;
      opacity: 0.75;
      margin-right: map-get($spacers, 1);

      &--right {
        display: block;
        margin-right: 0;
      }
    }

    &__Value {
      display: inline-block;
    }
  }
}
