@import "../../bootstrap-theme.scss";

.Actions-Dropdown {
  &__Disabled {
    background-color: $gray-100;
    color: $gray-500;

    &:hover,
    &:focus,
    &:active {
      background-color: $gray-100;
      color: $gray-500;
    }
  }

  &__Toggle {
    display: inline-block;
    margin-right: map-get($spacers, 2);
  }

  &--selected {
    background-color: $xc-brand-secondary-tint-12a;
    color: $xc-brand-primary-dark;
  }
}
