@import "../../../../bootstrap-theme.scss";

.Bill-Table-Sub-Item {
  &__Description {
    font-size: 12px;
    color: $xc-typo-disabled;
  }

  &__Currency {
    color: $xc-typo-disabled;
    margin-right: map-get($spacers, 1);
  }

  .badge {
    font-size: 10px;
    padding: map-get($spacers, 1);
  }

  td {
    padding: map-get($spacers, 4);
  }
}
