@import "../../bootstrap-theme.scss";

.Custom-Range-DatePicker {
  display: inline-block;
  padding: $input-padding-y;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  background-color: $input-bg;
  color: $input-color;
  border: $input-border-color solid $input-border-width;
  border-radius: $input-border-radius;
  display: inline-flex;
  align-items: center;

  input {
    max-width: 105px;
    font-family: "Manrope" !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    outline: none !important;
    text-align: left;

    &:hover {
      cursor: pointer;
    }
  }

  &__Icon {
    line-height: 24px;
    width: 24px;
    display: inline-block;
    text-align: center;
    border-radius: $input-border-radius;
    background-color: $xc-brand-primary-dark;
    color: $xc-typo-reverse-primary;
    margin-left: $input-padding-y;
    font-size: 13px;
  }

  &:hover {
    cursor: pointer;
  }

  &--disabled {
    background-color: $input-disabled-bg;

    input {
      color: $xc-typo-disabled !important;
      background-color: $input-disabled-bg;
    }
  }

  &--invalid {
    border-color: $form-feedback-invalid-color;

    .Custom-Range-DatePicker__Icon {
      background-color: $form-feedback-invalid-color;
    }
  }
}
