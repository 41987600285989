@import "../../../bootstrap-theme.scss";

.Overview {
  margin-top: map-get($spacers, 6);

  &__Go-Back {
    font-size: 12px;
    color: $xc-typo-disabled;
    display: inline-block;
    padding-left: map-get($spacers, 2);
    padding-right: map-get($spacers, 2);

    &__Label {
      font-weight: 600;
    }
  }
}
