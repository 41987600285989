@import "./bootstrap-theme.scss";
@import "./node_modules/bootstrap/scss/bootstrap";
@import "./animations.scss";

.App {
  &__NavBar {
    flex-basis: calc(40px + map-get($spacers, 4) * 3);
  }
  &__Content {
    flex: 1;
  }
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
  }
}

.breadcrumb {
  font-weight: $breadcrumb-font-weight;
}

h3 {
  font-size: 1.5rem;
  font-weight: 800;
}

h4 {
  font-size: 1.25rem;
  font-weight: 800;
  font-family: "Roboto", sans-serif;
}

h5 {
  font-size: 1rem;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}

h6 {
  font-size: 0.9375rem;
  font-weight: 800;
  font-family: "Roboto", sans-serif;
}

.card-header {
  position: relative;
  border-bottom: 0px;
  background-color: $secondary;
  border-radius: $btn-border-radius !important;
  margin: $spacer;
}

.modal-footer {
  position: relative;
  border-top: 0px;
  border-radius: $btn-border-radius !important;
  margin: $spacer;
  padding: map-get($spacers, 3);

  & button {
    padding: 14px;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
  }
}

.modal-header {
  font-size: 12px;
  font-weight: 600;
  padding: 21px 24px;
}

.ql-toolbar {
  border-top-left-radius: $input-border-radius;
  border-top-right-radius: $input-border-radius;
  border-color: $input-border-color !important;
}

.ql-container {
  border-bottom-left-radius: $input-border-radius;
  border-color: $input-border-color !important;
}

.ql-editor {
  min-height: 150px;
  max-height: 300px;
  overflow-y: scroll;
}

.ql-invalid {
  > .ql-toolbar {
    border-color: $form-feedback-invalid-color !important;
  }

  > .ql-container {
    border-color: $form-feedback-invalid-color !important;
  }
}

.ql-disabled {
  > .ql-toolbar {
    border-color: $input-disabled-border-color;
    background-color: $input-disabled-bg;
    color: $input-disabled-color;
  }

  > .ql-container {
    border-color: $input-disabled-border-color;
    background-color: $input-disabled-bg;
    color: $input-disabled-color;
  }
}

// Custom scrollbar
* {
  scrollbar-color: $primary $secondary;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-track {
  background: $secondary;
}

::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: $border-radius;
}

.badge-small {
  font-size: 12px;
}

.text-table-header {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: map-get($spacers, 4);
}

.table {
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 10px;
  border: 1px solid $border-color;

  thead {
    tr {
      border-collapse: collapse;
    }
  }

  th {
    box-shadow: inset 0 0 0 9999px $xc-material-surface-primary-alternative !important;
    border: 0px;
    font-size: 12px;
    font-weight: 600;

    border-bottom: 2px solid $border-color;

    &:first-child {
      border-top-left-radius: 10px;
    }

    &:last-child {
      border-top-right-radius: 10px;
    }
  }

  td {
    border-top: 1px solid $border-color;
    border-bottom: 0px;
  }

  tr:first-child {
    td {
      border-top: 0px;
    }
  }

  tr:last-child {
    td {
      border-bottom: 0;

      &:first-child {
        border-bottom-left-radius: 10px;
      }

      &:last-child {
        border-bottom-right-radius: 10px;
      }
    }
  }

  &-actions {
    border-left: 2px solid $border-color !important;
    text-align: center;
    vertical-align: middle;
    width: 70px;
  }

  &--disabled {
    box-shadow: inset 0 0 0 9999px $xc-material-surface-primary-alternative !important;
  }
}

tbody:not(:last-child) {
  tr:last-child {
    td {
      &:first-child {
        border-bottom-left-radius: 0px !important;
      }

      &:last-child {
        border-bottom-right-radius: 0px !important;
      }
    }
  }
}

.badge.bg-danger.text-danger {
  color: $xc-state-error-dark !important;
}

.badge.bg-warning.text-warning {
  color: $xc-state-warning-dark !important;
}

.badge.bg-success.text-success {
  color: $xc-state-success-dark !important;
}

.badge.bg-info.text-info {
  color: $xc-state-info-dark !important;
}

.badge.bg-secondary.text-secondary {
  color: $xc-brand-secondary-dark !important;
  background-color: $xc-brand-secondary-tint-12a;
}

.badge.bg-light.text-light {
  color: $xc-typo-disabled !important;
  background-color: $gray-100 !important;
}

.alert-danger {
  color: $xc-state-error-dark !important;
  background-color: $xc-state-error-tint-12a !important;
}

.alert-warning {
  color: $xc-state-warning-dark !important;
  background-color: $xc-state-warning-tint-12a !important;
}

.alert-success {
  color: $xc-state-success-dark !important;
  background-color: $xc-state-success-tint-12a !important;
}

.alert-info {
  color: $xc-brand-primary-dark !important;
  background-color: $xc-state-info-tint-12a !important;
}

.text-danger-default {
  color: $xc-state-error-default !important;
}

.text-warning-default {
  color: $xc-state-warning-default !important;
}

.text-success-default {
  color: $xc-state-success-default !important;
}

.text-success-dark {
  color: $xc-state-success-dark !important;
}

.text-info-default {
  color: $xc-state-info-default !important;
}

.badge.text-info.bg-info {
  color: $xc-brand-primary-dark !important;
  background-color: $xc-brand-primary-tint-12a;
}

.form-label {
  width: 100%;
  position: relative;

  &[aria-required="true"]::after {
    content: "*";
    position: absolute;
    right: 0;
    color: $form-feedback-invalid-color;
  }
}

.btn-outline {
  border-color: $border-color;

  &:hover {
    border-color: $border-color !important;
    background-color: $xc-material-surface-primary-default !important;
  }

  &:disabled {
    border-color: $border-color !important;
  }
}

.helper-text {
  font-size: 0.75rem;
  color: $xc-typo-disabled;
}

.form-switch {
  display: inline-block;

  .form-check-input {
    border: 0;
    height: 28px;
    background-color: $xc-brand-primary-light;

    &:checked {
      background-color: $xc-brand-secondary-default;
    }
  }
}

.placeholder {
  &--input {
    height: 40px;
    border-radius: $input-border-radius;
  }

  &--quill {
    height: 193px;
    border-radius: $input-border-radius;
    border-bottom-right-radius: 0px;
  }
}

.progress-bar.bg-primary {
  background-color: $xc-brand-secondary-dark !important;
  color: $xc-typo-reverse-primary;
}

.progress-bar.bg-secondary {
  background-color: $xc-brand-secondary-light !important;
}

.anchored {
  scroll-margin-top: 100px;
  animation: 0.85s ease-in-out 0.3s flash-border;
}
