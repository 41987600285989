@import "../../bootstrap-theme.scss";

.Collapse-Button {
  width: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: calc($input-border-radius / 2);
  background-color: $xc-material-surface-primary-default;
  font-weight: 900;
  font-size: 12px;
  color: $xc-typo-disabled;

  &:hover {
    cursor: pointer;
  }
}
