@import "../../bootstrap-theme.scss";

.Google-Login-Text {
  color: $xc-brand-secondary-dark !important;
  text-decoration: underline !important;

  &:hover {
    cursor: pointer;
  }
}
