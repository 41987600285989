@import "../../../../../bootstrap-theme.scss";

.Term-Row {
  &.indented {
    td {
      border-top: 1px solid $border-color !important;

      &:nth-child(n + 2) {
        box-shadow: inset 0 0 0 9999px $gray-100 !important;
      }
    }
  }

  &__Name,
  &__Label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }

  &__Label {
    color: $headings-color;
  }

  &__Icon {
    box-shadow: inset 0 0 0 9999px $xc-brand-primary-light !important;
    color: $xc-typo-reverse-primary;
    font-size: 14px;
  }
}
