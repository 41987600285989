@import "../../bootstrap-theme.scss";

.Icon-Card {
  padding: map-get($spacers, 4);
  border: 2px solid $xc-material-secondary-surface-hover;
  border-radius: $btn-border-radius;

  &__Header {
    display: flex;
    align-items: center;
    margin-bottom: map-get($spacers, 4);

    &__Icon {
      width: 56px;
      line-height: 56px;
      text-align: center;
      border-radius: $btn-border-radius;
      font-size: 32px;
      margin-right: map-get($spacers, 4);
    }
  }

  &__Content {
    font-size: 12px;
    font-weight: 400;
    color: $xc-typo-secondary;
  }
}
