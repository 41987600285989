@import "../../bootstrap-theme.scss";

.Stat-Banner {
  flex: 1;
  border-radius: $border-radius;
  border: 2px solid $border-color;
  padding: map-get($spacers, 4);
  font-size: 20px;
  font-weight: 500;

  &__Title {
    font-size: 12px;
    font-weight: 700;
    color: $xc-typo-disabled;
    margin-bottom: map-get($spacers, 4);
  }

  &__Content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: map-get($spacers, 2);

    .badge {
      font-size: 10px;
      padding: map-get($spacers, 1);
      border-radius: 20px;
    }
  }
}
