@import "../../bootstrap-theme.scss";

.Navigation-Bar-Item {
  width: 100%;
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
  color: $xc-brand-primary-default;
  margin-top: map-get($spacers, 4);
  margin-bottom: map-get($spacers, 5);

  &:hover {
    color: $primary !important;
    cursor: pointer;

    > .Navigation-Bar-Item__Icon:not(.Navigation-Bar-Item__Icon--active) {
      box-shadow: inset 0 0 0 2px #d0d0ed;
    }
  }

  &--active {
    color: $xc-brand-primary-dark;
  }

  a {
    color: $color-contrast-dark;
  }

  &__Label {
    font-size: 14px;
    font-weight: 600;
  }

  &__Icon {
    background-color: #9292bc05;
    width: 40px;
    line-height: 40px;
    margin-right: map-get($spacers, 4);
    text-align: center;
    font-size: 14px;
    box-shadow: inset 0 0 0 1px $xc-material-border;
    border-radius: $btn-border-radius;

    &--active {
      background-color: $xc-brand-primary-dark;
      color: $xc-typo-reverse-primary;
      box-shadow: inset 0 0 0 2px $xc-brand-primary-dark;
    }

    &:not(.Navigation-Bar-Item__Icon--active) {
      &:hover {
        box-shadow: inset 0 0 0 2px #d0d0ed;
      }
    }
  }
}
