@import "../../bootstrap-theme.scss";

.File-Drop-Input {
  display: flex;
  align-items: stretch;
  margin-bottom: map-get($spacers, 3);
  gap: map-get($spacers, 3);

  &__Image {
    width: 116px;

    border-radius: $input-border-radius;
    border: 1px solid $xc-material-border;
    font-size: 32px;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      object-fit: contain;
      max-height: 100%;
      max-width: 100%;
      border-radius: $input-border-radius;
    }
  }

  &__Form {
    flex: 1;
  }

  &__Zone {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 160px;
    border: 2px dashed $border-color;
    border-radius: $input-border-radius;
    background-color: $xc-material-surface-primary-alternative;
    font-size: 12px;

    &:hover:not(.File-Drop-Input__Zone--disabled) {
      border-color: $xc-brand-secondary-default;
      cursor: pointer;
    }

    &--small {
      height: 80px;
    }

    &--bold {
      font-weight: 800;
      font-family: "Roboto";
      font-size: 15px;
      color: $xc-typo-disabled;
    }

    &.invalid {
      border-color: $form-feedback-invalid-color;
    }
  }

  &__File {
    &__Icon {
      color: $xc-brand-primary-default;
      font-size: 32px;
    }

    &__Name {
      font-weight: 600;
      font-size: 12px;
    }

    &__Size {
      font-weight: 600;
      font-size: 12px;
      color: $xc-typo-disabled;
    }

    &__Remove {
      &:hover {
        color: $xc-material-secondary-surface-hover;
        cursor: pointer;
      }
    }

    &:not(:last-child) {
      margin-bottom: map-get($spacers, 2);
    }
  }
}
