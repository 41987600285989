@import "../../../../../../bootstrap-theme.scss";

.Xchange-Billing-Summary-Chart {
  width: 100%;
  height: 350px;
  position: relative;
  border: 2px solid $border-color;
  border-radius: $border-radius / 2;

  &__Container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &__Tooltip {
    background-color: $xc-material-surface-primary-alternative;
    box-shadow: -1px 2px 8px rgba(0, 0, 0, 0.05);
    padding: map-get($spacers, 2);
    border-radius: $border-radius / 2;

    &__Label {
      margin-bottom: map-get($spacers, 2);
    }

    &__Value {
      display: flex;
      align-items: center;
      margin-bottom: map-get($spacers, 1);
    }
  }

  &__Legends {
    color: $xc-typo-primary;
    display: flex;
    margin: auto;
    justify-content: center;

    &__Legend {
      display: flex;
      margin-right: map-get($spacers, 3);
      margin-left: map-get($spacers, 3);
      align-items: center;

      &__Color {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: $border-radius / 4;
        margin-right: map-get($spacers, 2);
      }
    }
  }
}
