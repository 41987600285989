@import "../../bootstrap-theme.scss";

.Paginated-Table {
  overflow-y: scroll !important;
  scrollbar-color: $primary $xc-material-surface-primary-alternative;
  border: 1px solid $border-color;
  border-top-left-radius: 10px;

  &__Table {
    width: 927px;
    border: 0px;
    border-radius: 0px;
    table-layout: fixed;
    margin-bottom: 0px;
  }

  th,
  td {
    padding: map-get($spacers, 4);

    &.table-actions {
      background-color: white;
      width: 62px;
      position: sticky;
      right: 0;
    }

    @supports (-moz-appearance: none) {
      &.table-actions {
        width: 59px;
      }
    }

    &:first-child {
      border-top-left-radius: 0px;
    }

    &:last-child {
      border-top-right-radius: 0px;
    }
  }

  > div {
    padding: 0px;
  }

  &::-webkit-scrollbar-track {
    background: $xc-material-surface-primary-alternative;
    border: 1px solid $border-color;
  }
}
