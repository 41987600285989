@import "../../bootstrap-theme.scss";

.Search-Filter {
  position: relative;

  &__Input {
    font-size: 0.875rem;
    font-weight: $input-placeholder-font-weight;
    border-color: $input-border-color;
    border-radius: $input-border-radius;
  }
  &__Button {
    width: 26px;
    height: 26px;
    position: absolute;
    top: 6px;
    right: 6px;
    border-radius: 10px;
  }
}
