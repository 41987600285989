@import "../../bootstrap-theme.scss";

.Toggle-Text-Input {
  background-color: $xc-material-surface-primary-alternative;
  border-radius: $btn-border-radius;
  padding: map-get($spacers, 4);

  &__Switch {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 800;

    .form-check-input {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__Input {
    margin-top: map-get($spacers, 3);
  }
}
