@import "../../bootstrap-theme.scss";

.Select-Badges {
  display: inline-block;

  &__Value-Container {
    padding: 0px !important;
  }

  &__Value {
    background-color: $xc-brand-primary-default !important;
    color: $xc-typo-reverse-primary !important;
    border-radius: 50px !important;
    padding-top: map-get($spacers, 2);
    padding-right: map-get($spacers, 3);
    padding-bottom: map-get($spacers, 2);
    padding-left: map-get($spacers, 3);
    display: flex;
    justify-content: center;
    font-size: 14px !important;
    font-weight: 600;
  }

  &__Value-Remove {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: map-get($spacers, 1);

    > div {
      padding: 0;
      font-size: 12px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__Control {
    border-radius: 50px !important;
    border: 0px !important;
    box-shadow: none !important;

    &--disabled {
      background-color: $input-disabled-bg !important;
    }
  }

  &__Input {
    border: 2px dashed $border-color;
    border-radius: 50px;
    padding-top: map-get($spacers, 2) !important;
    padding-right: map-get($spacers, 3);
    padding-bottom: map-get($spacers, 2) !important;
    padding-left: map-get($spacers, 3);
    font-size: 14px;
    font-weight: 600;
    flex: 0 !important;
    grid-area: auto;

    > input {
      min-width: 45px !important;

      &:focus {
        border-width: 0px !important;
      }
    }
  }

  &__Menu {
    width: 220px !important;
    border: 1px solid $border-color !important;
    box-shadow: none !important;
    border-radius: $input-border-radius !important;

    > div {
      padding: 0px;
      border-radius: $input-border-radius !important;

      > div {
        &:not(:first-child) {
          border-top: 1px solid $border-color;
        }

        &:nth-child(odd) {
          background-color: $xc-material-secondary-surface-default;
        }

        &:nth-child(even) {
          background-color: $xc-material-surface-primary-alternative;
        }

        &:hover {
          background-color: $xc-material-surface-primary-hover;
          cursor: pointer;
        }
      }
    }
  }
}
