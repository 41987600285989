@import "../../bootstrap-theme.scss";

.Password-Input {
  position: relative;

  &__Input {
    font-size: 0.875rem;
    font-weight: $input-placeholder-font-weight;
    border-color: $input-border-color;
    border-radius: $input-border-radius;
  }

  &__Button {
    border: 0;
    width: 26px;
    height: 26px;
    position: absolute;
    top: 7px;
    right: 7px;
    border-radius: 10px;

    &.invalid {
      background-color: $xc-state-error-dark;
      color: $white;

      &:hover {
        background-color: $xc-state-error-dark;
      }
    }
  }
}
