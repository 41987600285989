@import "../../bootstrap-theme.scss";

.Dotted-Progress-Bar {
  &__Steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  &__Step {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;

    &__Dot {
      position: absolute;
      top: -4px;
      height: 12px;
      width: 12px;
      border-radius: 12px;
      background-color: $xc-brand-primary-light;

      &--active {
        background-color: $xc-brand-primary-default;
        transition-delay: 0.375s;
        transition-duration: 0.375s;
        transition-property: background-color;
      }
    }

    &__Name {
      position: absolute;
      background-color: $xc-brand-primary-default;
      color: $xc-typo-reverse-primary;
      border-radius: $badge-border-radius;
      padding-top: 3px;
      padding-bottom: 3px;
      padding-left: 6px;
      padding-right: 6px;
      margin-top: map-get($spacers, 4);

      font-size: 12px;
      line-height: 12px;
      font-weight: 600;
      text-align: center;

      animation: dot-name-fade-in 0.75s linear;
    }
  }

  &__Bar {
    width: 100%;
    height: 3px;
    background-color: $xc-brand-primary-light;
    position: relative;

    &__Progress {
      height: 3px;
      background-color: $xc-brand-primary-default;
      position: absolute;
    }
  }
}

@keyframes dot-name-fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
