@import "../../../../../bootstrap-theme.scss";

.Invoice-Bill-Line-Item {
  border: 0px;

  > :not(caption) > * > * {
    background-color: transparent;
    box-shadow: none;
  }

  tr:first-child {
    td {
      border-top: 0px;
    }
  }

  &__Quantity {
    width: 75px;
  }

  &__Rate {
    width: 125px;
  }

  &__Total {
    width: 125px;
  }

  &__Extra {
    background-color: $xc-brand-primary-tint-12a;
    border-radius: $input-border-radius;
    padding: map-get($spacers, 4);
    margin-bottom: map-get($spacers, 4);

    &__Type {
      width: 75px;
    }

    &__Value {
      width: 145px;

      &__Error {
        width: 145px;
      }
    }

    .hstack:not(:first-child) {
      margin-top: map-get($spacers, 2);
    }
  }

  td {
    padding: map-get($spacers, 2);

    &.snug {
      width: 1%;
    }
  }
}
