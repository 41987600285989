@import "../../bootstrap-theme.scss";

.Select-Search {
  position: relative;

  &__List {
    background-color: $xc-material-secondary-surface-default;
    border: 1px solid $xc-material-border;
    z-index: 2;
    position: absolute;
    width: 100%;
    max-height: 250px;
    overflow-y: auto;
    border-radius: 4px;
    margin-top: map-get($spacers, 2);

    &__Option {
      padding: map-get($spacers, 3);
      color: $xc-typo-secondary;

      &__Title {
        font-size: 14px;
        font-weight: 600;
      }

      &__Subtitle {
        color: $xc-typo-disabled;
        font-size: 12px;
        font-weight: 500;
      }

      &--selected {
        background-color: $xc-brand-secondary-tint-12a !important;
        color: $xc-brand-primary-dark;
      }

      &:hover {
        cursor: pointer;
      }

      &:nth-child(odd) {
        background-color: $xc-material-surface-primary-alternative;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $xc-material-border;
      }
    }

    &__Empty {
      width: 100%;
      text-align: center;
      padding: map-get($spacers, 3);
      color: $xc-typo-disabled;
      font-size: 12px;
      font-weight: 500;
    }
  }
}
