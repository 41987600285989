@import "../../../../bootstrap-theme.scss";

.Domains-Step {
  max-width: 460px;
  padding-top: map-get($spacers, 8);

  &__Info {
    font-size: 16px !important;
    font-weight: 600 !important;

    &__Details {
      font-weight: 400 !important;
    }
  }
}
