@import "../../bootstrap-theme.scss";

.Icon-Checkbox {
  position: relative;
  background-color: $xc-material-primary-background;
  padding: map-get($spacers, 4);
  border-radius: $btn-border-radius;
  box-shadow: 0 0 0 1px $xc-material-border;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: $btn-transition;

  &--checked {
    box-shadow: 0 0 0 2px $xc-material-border,
      0 0 0 4px $xc-brand-secondary-default !important;
  }

  &:hover {
    box-shadow: 0 0 0 2px $xc-material-border;
    cursor: pointer;
  }

  &__Checkmark {
    position: absolute;
    top: calc(map-get($spacers, 2) - 4px);
    right: map-get($spacers, 2);
    color: $xc-material-border;
    font-size: 16px;

    &--checked {
      color: $xc-brand-secondary-default;
    }
  }

  &__Icon {
    width: 80px;
    line-height: 80px;
    text-align: center;
    border-radius: $btn-border-radius;
    font-size: 40px;
    margin-bottom: map-get($spacers, 4);
    box-shadow: 0px 4px 15px -2px #0000000d;
  }

  &__Label {
    font-size: 12px;
    font-weight: 700;
    text-align: center;

    &__Sub {
      font-size: 11px;
      font-weight: 500;
      color: $xc-typo-disabled;
      margin-top: map-get($spacers, 1);
    }
  }
}
