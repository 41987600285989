@import "../../../../../bootstrap-theme.scss";

.Xchange-Table-Row {
  &__Client-Badge {
    border-color: $xc-brand-secondary-tint-12a;
    color: $xc-brand-secondary-dark;
    font-size: 12px;
  }

  &__Xpert-Badge {
    color: $xc-state-info-dark;
    font-size: 12px;
  }
}
