@import "../../bootstrap-theme.scss";

.Navigation-Bar-Group {
  margin-left: map-get($spacers, 4);

  &__Label {
    margin-top: map-get($spacers, 6);
    margin-bottom: map-get($spacers, 4);

    color: $xc-brand-primary-dark;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    text-overflow: clip;
    overflow: hidden;
    white-space: nowrap;
    opacity: 0;

    transition: ease-in-out 0.1s;
    transition-property: opacity;
  }
}
