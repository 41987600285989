.Contract-Review-Dashboard {
  &__Step {
    &__Subtitle {
      font-weight: 600;
    }
  }

  &__Title {
    font-weight: 800;
  }
}
