@import "../../bootstrap-theme.scss";

.Custom-DatePicker {
  border: 0;
  max-width: 100px;
  font-family: "Manrope" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  outline: none !important;

  // Hides datepicker input without hiding the calendar
  &--hide-input {
    max-width: 0px !important;
    caret-color: transparent;
  }

  &__Container {
    border: 1px solid $border-color !important;
    padding: map-get($spacers, 3);
    z-index: 0;

    &__Footer {
      clear: both;
      margin-left: calc(map-get($spacers, 3) * -1);
      width: calc(100% + map-get($spacers, 5));
      border-top: 1px solid $border-color;
      padding-left: map-get($spacers, 3);
      padding-right: map-get($spacers, 3);
      padding-top: map-get($spacers, 3);
      display: flex;
    }
  }

  &__Wrapper {
    width: auto !important;
  }

  &__Calendar {
    font-family: "Manrope" !important;
    font-style: normal !important;

    &__Header {
      padding-bottom: map-get($spacers, 4);
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__Date {
        font-weight: 700 !important;
        font-size: 12px !important;
        text-transform: uppercase;
        display: inline-block;
      }
    }

    &__Day {
      margin: 0px !important;
      width: 40px !important;
      line-height: 40px !important;
      border-radius: 40px !important;
      font-weight: 600 !important;
      font-size: 12px !important;

      &--Disabled {
        color: $xc-typo-disabled !important;
        opacity: 0.5;
      }

      &--Selected {
        background-color: $xc-brand-primary-dark !important;
        color: $xc-typo-reverse-primary !important;
      }

      &:hover:not(.react-datepicker__day--in-selecting-range):not(
          .Custom-DatePicker__Calendar__Day--Disabled
        ):not(.react-datepicker__day--selected) {
        background-color: $xc-brand-primary-tint-4a !important;
        color: $xc-typo-primary !important;
      }
    }

    &__Week-Day {
      margin: 0 !important;
      font-family: "Roboto" !important;
      font-weight: 400 !important;
      font-size: 12px !important;
      width: 40px !important;
      line-height: 40px !important;
    }

    .react-datepicker__month-container:not(:last-of-type) {
      margin-right: map-get($spacers, 6);
    }

    .react-datepicker__header {
      border-bottom: 0 !important;
      background-color: $xc-material-secondary-surface-default !important;
      padding-top: 0 !important;
    }

    .react-datepicker__day--outside-month {
      visibility: hidden;
    }

    .react-datepicker__day--in-selecting-range:not(
        .react-datepicker__day--in-range
      ):not(.react-datepicker__day--selecting-range-start):not(
        .react-datepicker__day--selecting-range-end
      ) {
      background-color: $xc-material-surface-primary-default !important;
      color: $xc-brand-primary-dark !important;
      border-radius: 0px !important;
    }

    .react-datepicker__day--in-range:not(
        .react-datepicker__day--in-selecting-range
      ) {
      background-color: $xc-material-surface-primary-default !important;
      color: $xc-brand-primary-dark !important;
      border-radius: 0px !important;
    }

    .react-datepicker__day--range-start:not(
        .react-datepicker__day--in-selecting-range
      ):not(.react-datepicker__day--outside-month),
    .react-datepicker__day--selecting-range-start:not(
        .react-datepicker__day--outside-month
      ) {
      background-color: $xc-brand-primary-dark !important;
      color: $xc-typo-reverse-primary !important;
      border-radius: 40px !important;

      &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        width: 40px;
        height: 40px;
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
        background-color: $xc-material-surface-primary-default;
      }
    }

    .react-datepicker__day--range-end:not(
        .react-datepicker__day--in-selecting-range
      ):not(.react-datepicker__day--outside-month),
    .react-datepicker__day--selecting-range-end:not(
        .react-datepicker__day--outside-month
      ) {
      background-color: $xc-brand-primary-dark !important;
      color: $xc-typo-reverse-primary !important;
      border-radius: 40px !important;

      &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        width: 40px;
        height: 40px;
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
        background-color: $xc-material-surface-primary-default;
      }
    }

    .react-datepicker__day--today:not(.react-datepicker__day--in-range):not(
        .react-datepicker__day--in-selecting-range
      ):not(.Custom-DatePicker__Calendar__Day--Disabled):not(:hover) {
      background-color: $xc-material-secondary-surface-default !important;
      color: $xc-typo-primary !important;
    }

    .react-datepicker__day--selected {
      background-color: $xc-brand-primary-dark !important;
      color: $xc-typo-reverse-primary !important;
    }

    .react-datepicker__month {
      margin: 0 !important;
      margin-bottom: map-get($spacers, 3) !important;
    }

    .react-datepicker__week {
      margin: 0 !important;
    }
  }
}
