// Typography
$xc-typo-primary: #111;
$xc-typo-secondary: #514f5e;
$xc-typo-disabled: #6f6c83;
$xc-typo-reverse-primary: #f9f9f9;
$xc-typo-reverse-secondary: #c7c7c7;
$xc-typo-reverse-disabled: #b4b3b3;

// Brand
$xc-brand-primary-default: #62629c;
$xc-brand-primary-dark: #333271;
$xc-brand-primary-light: #d7d7e4;
$xc-brand-primary-tint-4a: #f9f9fb;
$xc-brand-primary-tint-12a: #ececf3;
$xc-brand-primary-tint-30a: #d0d0e1;
$xc-brand-secondary-default: #597cff;
$xc-brand-secondary-dark: #1f47db;
$xc-brand-secondary-light: #beccff;
$xc-brand-secondary-tint-4a: #f8faff;
$xc-brand-secondary-tint-12a: #ebefff;
$xc-brand-secondary-tint-30a: #cdd8ff;

// Material
$xc-material-surface-primary-default: #f2f2fa;
$xc-material-surface-primary-alternative: #f8f8fa;
$xc-material-surface-primary-hover: #e1e1ed;
$xc-material-secondary-surface-default: #ffffff;
$xc-material-secondary-surface-hover: #d0d0ed;
$xc-material-primary-background: #ffffff;
$xc-material-border: #e6e3ee;

// States
$xc-state-active-default: #5755e9;
$xc-state-success-default: #03ca8e;
$xc-state-success-dark: #037c58;
$xc-state-success-light: #9ef4cb;
$xc-state-success-tint-4a: #f5fefa;
$xc-state-success-tint-12a: #e1faef;
$xc-state-success-tint-30a: #b4f3d8;
$xc-state-error-default: #e95050;
$xc-state-error-dark: #aa1010;
$xc-state-error-light: #ffb7b7;
$xc-state-error-tint-4a: #fff5f7;
$xc-state-error-tint-12a: #ffe0e7;
$xc-state-error-tint-30a: #ffb2b2;
$xc-state-warning-default: #ff9a23;
$xc-state-warning-dark: #b94b0e;
$xc-state-warning-light: #ffca8b;
$xc-state-warning-tint-4a: #fffbf6;
$xc-state-warning-tint-12a: #fff2e4;
$xc-state-warning-tint-30a: #ffe0bd;
$xc-state-info-default: #949cb3;
$xc-state-info-dark: #646c8c;
$xc-state-info-light: #e0e5f4;
$xc-state-info-tint-4a: #fbfbfc;
$xc-state-info-tint-12a: #f2f3f6;
$xc-state-info-tint-30a: #dfe1e8;
