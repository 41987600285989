@import "../../bootstrap-theme.scss";

.Acknowledgement-Page {
  &__Header {
    padding: 0 calc(map-get($spacers, 8) * 2) map-get($spacers, 7)
      calc(map-get($spacers, 8) * 2);

    @include media-breakpoint-down(md) {
      padding: 0 calc(map-get($spacers, 4) * 2) map-get($spacers, 7)
        calc(map-get($spacers, 4) * 2);
    }

    &__Title {
      font-size: 32px;
      font-weight: 800;
      color: $xc-typo-reverse-primary;
    }

    &__Subtitle {
      font-size: 16px;
      font-weight: 400;
      color: $xc-typo-reverse-primary;
      opacity: 0.75;
    }
  }

  &__Footer {
    padding: map-get($spacers, 4) calc(map-get($spacers, 8) * 2)
      calc(map-get($spacers, 8) * 2) calc(map-get($spacers, 8) * 2);

    @include media-breakpoint-down(md) {
      padding: map-get($spacers, 4) calc(map-get($spacers, 4) * 2)
        map-get($spacers, 8) calc(map-get($spacers, 4) * 2);
    }
  }
}
