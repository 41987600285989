@import "../../bootstrap-theme.scss";

.Document-Card {
  background-color: $xc-material-primary-background;
  border-radius: $btn-border-radius;
  padding: map-get($spacers, 3);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: map-get($spacers, 2);
  }

  &--secondary {
    background-color: $xc-material-surface-primary-alternative;
  }

  &__Icon {
    height: 30px;
    width: 32px;
    background-color: $gray-200;
    color: $xc-typo-secondary;
    border-radius: $btn-border-radius / 2;
    margin-right: map-get($spacers, 3);

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 18px;
    }
  }

  &__Title-Wrapper {
    display: flex;
    align-items: center;
    flex: 4;

    &__Title {
      font-size: 12px;
      font-weight: 600;
      margin-right: map-get($spacers, 3);

      &__Sub-Name {
        color: $xc-typo-disabled;
      }
    }
  }

  &__Type {
    flex: 1;

    .badge {
      font-weight: 600;
      font-size: 12px;
    }
  }

  &__Info {
    flex: 2;
    text-align: right;
    font-size: 12px;
    font-weight: 600;
    color: $xc-typo-disabled;
  }

  &__Button {
    &:not(:last-child) {
      margin-right: map-get($spacers, 2);
    }
  }

  &__Actions {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $xc-typo-disabled;
    margin-left: map-get($spacers, 4);
    margin-right: map-get($spacers, 3);
  }
}
