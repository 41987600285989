@import "../../bootstrap-theme.scss";

.Custom-Date-Input {
  padding: $input-padding-y;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  background-color: $input-bg;
  color: $input-color;
  border: $input-border-color solid $input-border-width;
  border-radius: $input-border-radius;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__Icon {
    line-height: 26px;
    width: 26px;
    display: inline-block;
    text-align: center;
    border-radius: $input-border-radius;
    background-color: $xc-brand-primary-dark;
    color: $xc-typo-reverse-primary;
    margin-left: $input-padding-y;
    font-size: 13px;
  }

  &--invalid {
    border-color: $form-feedback-invalid-color !important;

    .Custom-Date-Input__Icon {
      background-color: $form-feedback-invalid-color !important;
    }
  }
}
