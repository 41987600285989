@import "../../../bootstrap-theme.scss";

.Bill-Table-Item {
  &__General {
    &__Description {
      font-size: 12px;
      color: $xc-typo-disabled;
      margin-left: map-get($spacers, 3);
    }
  }

  &__Content {
    display: block;

    &__Virtuoso {
      width: 100%;
      height: 300px !important;
      border-bottom-left-radius: 10px;
      overflow-y: scroll !important;
      scrollbar-color: $primary $xc-material-surface-primary-alternative;
      &::-webkit-scrollbar-track {
        background: $xc-material-surface-primary-alternative;
        border-left: 1px solid $border-color;
      }

      > div {
        padding: 0px;
      }

      tr:first-child {
        td {
          border-top: 2px solid $border-color !important;
        }
      }

      td.table-actions {
        width: 61px;
      }

      @supports (-moz-appearance: none) {
        td.table-actions {
          width: 58px;
        }
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
}
