@import "../../bootstrap-theme.scss";

.Step-Card {
  &__Label {
    &__Subtitle {
      font-size: 12px;
      font-weight: 600;
    }

    &__Title {
      font-size: 20px;
      font-weight: 800;
    }

    &__Checkbox {
      background-color: $white;
      border: 1px solid $border-color;
      border-radius: $btn-border-radius;

      .form-check {
        display: flex;
        align-items: center;

        .form-check-input {
          margin-top: 0px;
          margin-right: map-get($spacers, 2);
        }

        .form-check-label {
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
  }

  &__Footer {
    border-top-width: 1px;
    background-color: $xc-material-surface-primary-alternative;
  }
}
