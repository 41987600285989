@import "../../../bootstrap-theme.scss";

.Modal-Tabs {
  background-color: $xc-material-surface-primary-alternative;
  border-radius: 8px;

  .btn {
    font-weight: 600;
    &-outline-light {
      border: 1px solid $xc-material-border;
      color: $xc-typo-disabled;
      background-color: $xc-material-surface-primary-alternative;
    }
  }
}
