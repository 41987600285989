@import "../../bootstrap-theme.scss";

.List-Item-Checkbox {
  box-shadow: 0px 0px 0px 1px $border-color;
  border-radius: $btn-border-radius;
  padding: map-get($spacers, 2);

  &__Contenant {
    display: flex;
    align-items: stretch;
    padding-right: map-get($spacers, 3);

    &__Checkbox-Zone {
      background-color: $xc-material-surface-primary-default;
      color: $xc-typo-disabled;
      border-radius: $btn-border-radius / 2;
      min-height: 50px;
      display: flex;
      align-items: center;
      padding-left: map-get($spacers, 2);
      padding-right: map-get($spacers, 2);
      margin-right: map-get($spacers, 3);
      font-size: 12px;
    }

    &__Details {
      display: flex;
      align-items: start;
      padding-top: map-get($spacers, 1);
      padding-bottom: map-get($spacers, 1);
      flex: 1;

      &__Image {
        width: 34px;
        border-radius: calc($border-radius / 4);
        margin-right: map-get($spacers, 3);
      }

      &__General {
        flex: 1;
        max-width: 50%;
        padding-right: map-get($spacers, 4);

        &__Title {
          font-size: 14px;
          font-weight: 600;
        }

        &__Subtitle {
          margin-left: map-get($spacers, 1);
          font-size: 12px;
          font-weight: 700;
          color: $xc-typo-disabled;
          display: flex;
          align-items: center;

          &::before {
            content: "|";
            margin-right: map-get($spacers, 1);
            font-size: 15px;
            font-weight: 400;
          }
        }

        &__Description {
          margin-top: map-get($spacers, 1);
          font-size: 12px;
          font-weight: 400;
          color: $xc-typo-disabled;
        }
      }

      &__Statistic {
        width: 20%;

        &__Label {
          &__Name {
            font-size: 12px;
            font-weight: 600;
            color: $xc-typo-secondary;
            margin-right: map-get($spacers, 1);
          }

          &__Badge {
            .badge {
              font-size: 10px !important;
            }
          }
        }

        &__Value {
          font-size: 12px;
          font-weight: 400;
          min-height: 1px;
        }
      }

      &__Badges {
        color: $xc-brand-secondary-dark;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &__Badge {
          max-width: 125px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &__Facts {
        margin-top: 2px;

        &__Fact {
          margin-left: map-get($spacers, 2);
          font-size: 12px;
          font-weight: 700;
          color: $xc-typo-secondary;
        }

        &__Fact--prefix-suffix {
          color: $xc-typo-reverse-disabled;
        }
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: map-get($spacers, 4);
  }

  &--checked {
    box-shadow: 0px 0px 0px 2px $xc-brand-secondary-default;

    .List-Item-Checkbox__Contenant__Checkbox-Zone {
      background-color: $xc-brand-secondary-default;
      color: $xc-typo-reverse-primary;
    }
  }

  &--disabled {
    box-shadow: 0px 0px 0px 2px $xc-material-surface-primary-alternative;
    color: $xc-typo-disabled;

    .List-Item-Checkbox__Contenant__Checkbox-Zone {
      background-color: $xc-material-surface-primary-alternative;
    }

    &.List-Item-Checkbox--checked {
      box-shadow: 0px 0px 0px 2px $xc-brand-secondary-light;

      .List-Item-Checkbox__Contenant__Checkbox-Zone {
        background-color: $xc-brand-secondary-light;
        color: $xc-typo-reverse-primary;
      }
    }
  }

  &:hover:not(.List-Item-Checkbox--disabled) {
    cursor: pointer;
  }

  &:hover:not(.List-Item-Checkbox--checked):not(.List-Item-Checkbox--disabled) {
    box-shadow: 0px 0px 0px 2px $xc-material-secondary-surface-hover;

    .List-Item-Checkbox__Contenant__Checkbox-Zone {
      background-color: $xc-material-secondary-surface-hover;
    }
  }
}
