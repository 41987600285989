@import "../../../../../../bootstrap-theme.scss";

.Term-Table {
  th {
    vertical-align: top;
  }

  &__Currency {
    color: $xc-typo-reverse-disabled;
  }
}
