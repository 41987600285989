@import "../../../bootstrap-theme.scss";

.Overview-Menu-Group {
  &__Title {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: $xc-typo-secondary;
    text-transform: uppercase;
    margin-bottom: map-get($spacers, 4);
    letter-spacing: 0.05em;
    padding-left: map-get($spacers, 2);
  }
}
