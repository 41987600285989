@import "../../../../../../../src/bootstrap-theme.scss";

.Company-Details-Read {
  &__Header {
    display: flex;
    padding-left: map-get($spacers, 2);
    padding-right: map-get($spacers, 2);
    margin-bottom: map-get($spacers, 4);

    &__Logo {
      min-width: 80px;
      width: 80px;
      height: 80px;
      background-color: $secondary;
      border-radius: $border-radius;
      object-fit: contain;
      margin-right: map-get($spacers, 4);

      &[src] {
        background-color: $white;
      }
    }

    &__Legal-Name {
      font-size: 24px;
      font-weight: 800;
      margin-right: map-get($spacers, 2);
    }

    &__Common-Name {
      font-size: 24px;
      font-weight: 600;
      color: $xc-typo-disabled;
    }

    &__Info {
      display: flex;
      align-items: center;
      margin-top: map-get($spacers, 2);

      .badge {
        font-size: 14px;
        font-weight: 600;
        border-radius: $border-radius;
        margin-right: map-get($spacers, 2);
        margin-bottom: map-get($spacers, 2);
      }
    }
  }

  &__Body {
    display: flex;
    gap: map-get($spacers, 4);
  }
}
