@import "../../../../../src/bootstrap-theme.scss";

.Company-Overview {
  &__Header {
    display: flex;
    padding-left: map-get($spacers, 2);
    padding-right: map-get($spacers, 2);

    &__Logo {
      min-width: 64px;
      width: 64px;
      height: 64px;
      background-color: $secondary;
      border-radius: $border-radius;
      object-fit: contain;
      margin-right: map-get($spacers, 4);

      &[src] {
        background-color: $white;
      }
    }

    &__Name {
      font-size: 20px;
      font-weight: 800;
    }

    &__Info {
      display: flex;
      align-items: center;
      margin-top: map-get($spacers, 2);

      .badge {
        font-size: 10px;
        font-weight: 600;
        padding: 3px 5px 3px 5px;
      }
    }
  }

  &__Create-Box {
    color: $xc-typo-secondary;
    line-height: 40px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    border: 1px dashed $border-color;
    border-radius: $btn-border-radius;

    &--disabled {
      color: $xc-typo-disabled;
    }

    &:not(.Company-Overview__Create-Box--disabled) {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
