@import "../../../../bootstrap-theme.scss";

.Xchange-Edit__Products {
  &__Table {
    border: 1px solid $table-border-color;

    &__Icons {
      width: 40px;
    }

    &__Actions {
      width: 50px;
    }

    th {
      box-shadow: inset 0 0 0 9999px $xc-material-surface-primary-alternative !important;
    }

    th,
    td {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 150px;
    }
  }
}
