@import "../../bootstrap-theme.scss";

.Company-Card {
  &__Logo {
    display: inline-block;
    min-width: 64px;
    width: 64px;
    height: 64px;
    background-color: $secondary;
    border-radius: $border-radius;
    object-fit: contain;

    &[src] {
      background-color: $white;
    }
  }

  &__Xchange-Status {
    &--active {
      background-color: $xc-state-active-default !important;
    }
    &--completed {
      background-color: $xc-state-success-dark !important;
    }
  }

  &__Type {
    &--client {
      background-color: $xc-brand-primary-default !important;
      color: $light !important;
      border: None !important;
    }
    &--xpert {
      background-color: transparent !important;
      color: $xc-state-info-dark !important;
    }
  }

  &:hover {
    > .card {
      border-radius: $btn-border-radius;
      background-color: $xc-material-surface-primary-alternative !important;
    }
    cursor: pointer;
  }

  &__Domain {
    background-color: transparent !important;
    color: $xc-state-info-dark !important;
  }
  &__Overflow {
    color: $xc-state-info-dark !important;
    font-size: 0.75rem;
  }
}
