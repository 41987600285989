@import "../../../bootstrap-theme.scss";

.Contract-Party-Signatory-Selection {
  padding: map-get($spacers, 3);
  margin-top: map-get($spacers, 5);
  border-radius: $input-border-radius;
  border: 1px solid $border-color;

  &__Title {
    font-weight: 600;
  }
}
