@import "../../../../../bootstrap-theme.scss";

.Contract-Signee-List-Item {
  &__Badge {
    &--date {
      background-color: $xc-material-surface-primary-alternative !important;
      color: $xc-state-info-dark !important;
    }
  }
}
