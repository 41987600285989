@import "../../bootstrap-theme.scss";

.Navigation-Bar {
  background-color: $secondary;
  width: calc(40px + map-get($spacers, 4) * 2);
  margin-top: map-get($spacers, 4);
  margin-left: map-get($spacers, 4);
  height: calc(100% - map-get($spacers, 4) * 2);
  position: fixed;
  z-index: 1000;

  overflow: hidden;

  box-shadow: 5px 4px 16px 2px #00000005;
  border-radius: $border-radius;

  transition: ease-in-out 0.1s;
  transition-property: width;

  &__Inner {
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: $xc-brand-primary-default;
    }

    scrollbar-width: thin;
    scrollbar-color: $xc-brand-primary-default $secondary;
  }

  .card-body {
    padding: map-get($spacers, 0);
  }

  &__Header {
    background-color: $secondary;

    &__Logo {
      width: 40px;
    }
  }

  &__Footer {
    background-color: $secondary;
    border: 0px;
    margin: 0px;
    padding: 0px;
  }

  a {
    color: $color-contrast-dark;
  }

  &:hover {
    width: 208px;

    .Navigation-Bar__Header__Brand--small {
      display: none;
    }

    .Navigation-Bar__Header__Brand--full {
      display: block;
    }

    .Navigation-Bar-Group__Label {
      opacity: 0.5;
    }
  }
}
