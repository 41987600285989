@import "../../../../bootstrap-theme.scss";

.Xchange-Edit__Overview {
  &__Placeholder {
    height: 675px;
  }

  &__Header {
    padding-left: map-get($spacers, 2);
    padding-right: map-get($spacers, 2);

    &__Name {
      font-size: 20px;
      font-weight: 800;
    }

    &__Info {
      display: flex;
      align-items: center;
      margin-top: map-get($spacers, 2);

      .badge {
        font-size: 10px;
        font-weight: 600;
        padding: 3px 5px 3px 5px;
      }
    }
  }

  &__Project-Code {
    font-size: 12px;
    font-weight: 500;
    color: $xc-typo-secondary;

    display: flex;
    align-items: center;

    &__Copy-Button {
      visibility: hidden;
      margin-left: map-get($spacers, 2);

      font-size: 11px !important;
      padding: 2px 4px 2px 4px !important;
    }

    &__Wrapper {
      flex: 2;

      &:hover {
        .Xchange-Edit__Overview__Project-Code {
          color: $xc-brand-secondary-default;
          cursor: pointer;

          > .Xchange-Edit__Overview__Project-Code__Copy-Button {
            visibility: inherit;
          }
        }
      }
    }
  }
}
