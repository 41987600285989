@import "../../bootstrap-theme.scss";

.Card-Placeholder {
  background-color: $xc-material-surface-primary-alternative;
  border-radius: $btn-border-radius;
  color: $xc-typo-disabled;

  &__Circle {
    line-height: 90px;
    width: 90px;
    border-radius: 50%;
    text-align: center;
    background-color: $white;
    font-size: 2em;
    color: $xc-material-secondary-surface-hover;
  }

  &__Title {
    font-size: 20px;
    font-weight: 800;
    font-family: "Roboto";
  }

  &__Description {
    font-size: 16px;
    font-weight: 400;
  }
}
