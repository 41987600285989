@import "../../bootstrap-theme.scss";

.Select-With-Creation {
  &__ValueContainer {
    display: flex !important;
  }

  &__MultiValue {
    background-color: $xc-brand-primary-default !important;
    color: $xc-typo-reverse-primary !important;
    border-radius: $input-border-radius !important;
    padding-top: map-get($spacers, 1);
    padding-right: map-get($spacers, 2);
    padding-bottom: map-get($spacers, 1);
    padding-left: map-get($spacers, 2);
    font-size: 12px;
  }

  &__MultiValueRemove {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: map-get($spacers, 1);

    > div {
      background-color: $xc-material-secondary-surface-default !important;
      color: $xc-brand-primary-default !important;
      border-radius: $border-radius;
      padding: 0;

      > svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  &__Control {
    padding-top: 4px;
    padding-bottom: 4px;
    border-color: $border-color !important;
    border-radius: $input-border-radius !important;
    box-shadow: none !important;
  }

  &__Input {
    border: 2px dashed $border-color;
    border-radius: 4px;
    padding-top: map-get($spacers, 1);
    padding-right: map-get($spacers, 2);
    padding-bottom: map-get($spacers, 1);
    padding-left: map-get($spacers, 2);
    flex: 0 !important;
    grid-area: auto;

    > input {
      min-width: 40px !important;

      &:focus {
        border-width: 0px !important;
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  &__Menu {
    width: 220px !important;
    border: 1px solid $border-color !important;
    box-shadow: none !important;
    border-radius: $input-border-radius !important;

    > div {
      padding: 0px;
      border-radius: $input-border-radius !important;

      > div {
        &:not(:first-child) {
          border-top: 1px solid $border-color;
        }

        &:nth-child(odd) {
          background-color: $xc-material-secondary-surface-default;
        }

        &:nth-child(even) {
          background-color: $xc-material-surface-primary-alternative;
        }

        &:hover {
          background-color: $xc-material-surface-primary-hover;
          cursor: pointer;
        }
      }
    }
  }

  &--invalid {
    .Select-With-Creation__Control {
      border-color: $form-feedback-invalid-color !important;
    }
  }
}
