@import "../../../../bootstrap-theme.scss";

.Xchange-Edit__Type-Select {
  &__Select-Box {
    padding-top: 40%;
    padding-bottom: 40%;
    border: 1px solid $card-border-color;
    border-radius: $border-radius;

    &.selected {
      background-color: $secondary;
      border: 1px solid $secondary;
      border-radius: $border-radius;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
