@import "../../bootstrap-theme.scss";

.Product-Card {
  border-radius: $btn-border-radius;

  &__Status {
    &--planning,
    &--active,
    &--archived {
      border-radius: 4px;
      font-size: 12px;
      font-weight: 600;
    }
    &--planning {
      color: $xc-state-info-dark;
      background-color: $xc-state-info-tint-12a !important;
    }
    &--active {
      color: $xc-brand-secondary-dark;
      background-color: $xc-brand-secondary-tint-12a !important;
    }
    &--archived {
      color: $xc-state-info-dark;
      background-color: $xc-state-info-tint-12a !important;
    }
  }

  &:hover {
    background-color: $xc-material-surface-primary-alternative !important;
    cursor: pointer;
  }
}
