@import "../../bootstrap-theme.scss";

.Custom-Select {
  &__Placeholder {
    font-size: 0.875rem;
    font-weight: $input-placeholder-font-weight;
    color: $input-placeholder-color !important;
  }
  &__Control {
    min-height: $input-height !important;
    border-color: $input-border-color !important;
    border-radius: $input-border-radius !important;

    &--disabled {
      background-color: $input-disabled-bg !important;
    }

    &--invalid {
      border-color: $form-feedback-invalid-color !important;
    }
  }

  &__Dropdown-Indicator {
    color: $input-placeholder-color !important;
    width: 26px;
    height: 26px;
    background-color: $secondary;
    border-radius: 8px;

    &--invalid {
      background-color: $form-feedback-invalid-color !important;
      color: $xc-state-error-tint-12a !important;
    }
  }

  &__Menu {
    box-shadow: none !important;
    border: 1px solid $border-color;

    &--small {
      max-width: 200px;
    }
  }

  &__Menu-List {
    &--with-sub-options {
      overflow: visible !important;
    }
  }

  &__Option {
    font-weight: $input-placeholder-font-weight;
    background-color: $xc-material-secondary-surface-default !important;
    padding: 0px !important;

    &--selected {
      color: $input-placeholder-color !important;
      font-weight: $input-placeholder-font-weight;
      background-color: $xc-brand-secondary-tint-12a !important;
      padding: 0px !important;
    }

    &__Label {
      width: 100%;
      padding: 8px 12px;

      &--Selectable {
        &:hover {
          cursor: pointer;
        }
      }
    }

    &__Sub-Options-Menu {
      position: absolute;
      visibility: hidden;
      width: 200px;
      z-index: 1;
      background-color: hsl(0, 0%, 100%);
      border-radius: 4px;
      border: 1px solid $border-color;
      margin-top: -5px;
      margin-left: 100%;
      box-sizing: border-box;

      &__List {
        max-height: 300px;
        overflow-y: auto;
        position: relative;
        padding-bottom: 4px;
        padding-top: 4px;
        box-sizing: border-box;

        &__Option {
          cursor: default;
          display: block;
          font-size: inherit;
          width: 100%;
          color: inherit;
          padding: 8px 12px;
          box-sizing: border-box;

          &--selected {
            background-color: $xc-brand-secondary-tint-12a !important;
          }

          &:hover {
            background-color: $xc-material-surface-primary-alternative;
            cursor: pointer;
          }
        }

        &__Search {
          border-radius: 0;
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
          border: 0;
          margin-top: -4px;
          border-bottom: 2px solid $border-color;
          background-color: $xc-material-surface-primary-default;
          box-shadow: none !important;
          padding-left: 35px;

          &:focus {
            background-color: $xc-material-surface-primary-default;
            border-color: $border-color;
          }

          &__Icon {
            position: absolute;
            margin-top: 8px;
            margin-left: 10px;
            color: $xc-typo-disabled;
            pointer-events: none;
          }
        }
      }
    }

    &:hover {
      background-color: $xc-material-surface-primary-alternative !important;

      > .Custom-Select__Option__Sub-Options-Menu {
        visibility: visible;
      }
    }
  }
}
