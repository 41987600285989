@import "../../bootstrap-theme.scss";

.Company-Status-Badge {
  &--active,
  &--inactive {
    border-radius: 4px;
  }
  &--active {
    color: $xc-brand-secondary-dark;
    background-color: $xc-brand-secondary-tint-12a !important;
  }
  &--inactive {
    color: $xc-state-info-dark;
    background-color: $xc-state-info-tint-12a !important;
  }
}
