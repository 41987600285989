@import "../../bootstrap-theme.scss";

.Term-Card {
  border-radius: $btn-border-radius;

  &__Badges {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    &__Badge {
      color: $xc-state-info-dark;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &__Domain {
        color: $xc-brand-secondary-dark !important;
        background-color: $xc-brand-secondary-tint-12a !important;
      }
    }

    &__ExtraBadges {
      display: inline-block;
      vertical-align: middle;
      margin-top: 2px;
      color: #6c757d;
    }
  }

  &:hover {
    background-color: $xc-material-surface-primary-alternative !important;
    cursor: pointer;
  }
}
