@import "../../../../../bootstrap-theme.scss";

.Contract-Reviewer-List-Item {
  &__Badge {
    &--date {
      background-color: $xc-material-surface-primary-alternative !important;
      color: $xc-state-info-dark !important;
    }
    &--status {
      font-size: 14px;
    }
  }
}
