@import "../../../../../bootstrap-theme.scss";

.Product-Row {
  td {
    border-top: 1px solid $border-color !important;
  }

  &__Icon {
    box-shadow: inset 0 0 0 9999px $xc-brand-primary-default !important;
    color: $xc-typo-reverse-primary;
  }

  &__Name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }

  &__Label {
    color: $headings-color;
  }
}
