@import "../../../../bootstrap-theme.scss";

.Complete-Step {
  max-width: 460px;

  &__Login-Google {
    margin-top: map-get($spacers, 2);
    font-size: 12px;
    font-weight: 400;
    color: $xc-typo-secondary;
    text-align: center;
  }
}
