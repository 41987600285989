@import "../../bootstrap-theme.scss";

.Page {
  display: flex;
  align-items: stretch;
  min-height: 100%;

  &__Header {
    &__Title {
      font-size: 32px;
      font-weight: 800;
    }

    &__SubTitle {
      font-size: 14px;
      font-weight: 600;
      color: $xc-typo-disabled;
    }

    &--primary {
      background-color: $primary;
      border-radius: $border-radius;
      color: $white;
      padding-top: map-get($spacers, 7) !important;

      &__Title {
        color: $white;
      }
    }
  }

  &__Breadcrumb--primary {
    display: inline-block;
    background-color: $xc-brand-primary-default;
    border-radius: $btn-border-radius;
    color: $xc-typo-reverse-secondary;

    .breadcrumb {
      margin: 0;
      padding-top: map-get($spacers, 1);
      padding-right: map-get($spacers, 2);
      padding-bottom: map-get($spacers, 1);
      padding-left: map-get($spacers, 2);
    }
  }

  &__Tabs {
    justify-content: space-between;

    &__Item {
      display: flex;
      align-items: center;
      align-self: center;
      flex: 1;
      border-radius: $btn-border-radius;
      background-color: $xc-brand-primary-default;
      font-size: 14px;
      padding: map-get($spacers, 1) !important;

      &__Label {
        font-family: "Inter";
        font-size: 16px;
        font-weight: 800;
        color: $xc-typo-reverse-secondary;

        &--active {
          color: $xc-typo-primary;
        }
      }

      &__Desc {
        font-size: 12px;
        font-weight: 400;
        color: $xc-typo-reverse-disabled;

        &--active {
          color: $xc-typo-secondary;
        }
      }

      &__Add-Label {
        font-size: 14px;
        font-weight: 600;
        color: $xc-typo-reverse-secondary;
      }

      &--active {
        background-color: $white;
      }

      &--creatable {
        font-size: 16px;
        justify-content: center;
        background-color: transparent;
        border: 1px dashed $border-color;
      }

      &--disabled {
        &:hover {
          cursor: initial !important;
        }
      }

      &:not(:last-child) {
        margin-right: map-get($spacers, 4);
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__Content {
    width: 100%;
    padding: map-get($spacers, 6);
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;

    &--with-sidebar {
      max-width: 1112px;
    }
  }

  &__SideBar {
    width: calc(288px + map-get($spacers, 4) * 2);
    border-right: 1px solid $border-color;
    flex-shrink: 0;

    &__Content {
      position: fixed;
      width: 320px;
      overflow-y: auto;
      padding: map-get($spacers, 4);
      max-height: 100%;

      &::-webkit-scrollbar {
        width: 2px;
      }
    }
  }
}
