@import "../../../bootstrap-theme.scss";

.Xchange-Invoice-Page {
  &__Table-Header {
    border-bottom: 1px solid $border-color;
    margin-bottom: map-get($spacers, 5);

    &__Table {
      padding: map-get($spacers, 5);
      padding-bottom: map-get($spacers, 1);
      margin-bottom: 0px;

      border: 0px;
      font-size: 12px;
      font-weight: 700;

      > :not(caption) > * > * {
        background-color: transparent;
        box-shadow: none;
      }

      tr:first-child {
        td {
          border-top: 0px;
        }
      }

      td {
        padding: map-get($spacers, 2);
      }

      &__Quantity {
        width: 91px;
      }

      &__Rate {
        width: 141px;
      }

      &__Total {
        width: 141px;
      }

      &__Actions {
        width: 45px;
      }
    }
  }

  &__Summary {
    border-top: 1px solid $border-color;
    padding-top: map-get($spacers, 5);
    padding-bottom: map-get($spacers, 5);
    margin-left: map-get($spacers, 5);
    margin-right: map-get($spacers, 5);
    margin-top: map-get($spacers, 5);

    .hstack {
      &:not(:last-child) {
        margin-bottom: map-get($spacers, 2);
      }
    }

    &__Item {
      color: $xc-typo-secondary;

      &__Description {
        color: $xc-typo-disabled;
        font-size: 12px;
        font-weight: 400;
      }
    }

    &__Amount {
      color: $xc-typo-primary;
    }

    &--total {
      margin-top: 0px;
      font-weight: 600;
      font-size: 16px;
    }
  }
}
