@import "../../../bootstrap-theme.scss";

.Xchange-Bill-Page {
  &__Table-Header {
    margin-bottom: map-get($spacers, 1);

    &__Table {
      border: 0px;
      padding: map-get($spacers, 4);
      padding-bottom: map-get($spacers, 1);
      margin-bottom: 0px;

      thead {
        border: 0px;
        font-size: 12px;
        font-weight: 700;

        tr:first-child {
          td {
            border-top: 0px;
          }
        }
      }

      > :not(caption) > * > * {
        background-color: transparent;
        box-shadow: none;
      }

      tbody {
        tr:first-child {
          td {
            border-top: 1px solid $border-color !important;
          }
        }

        tr {
          border-color: transparent;
        }
      }

      td {
        padding: map-get($spacers, 4);
      }

      &__Quantity {
        width: 110px;
      }

      &__Rate {
        width: 170px;
      }

      &__Total {
        width: 170px;
      }
    }
  }

  &__Summary {
    border-top: 1px solid $border-color;
    padding: map-get($spacers, 4);
    margin-left: map-get($spacers, 4);
    margin-right: map-get($spacers, 4);
    margin-top: map-get($spacers, 4);

    .hstack {
      &:not(:last-child) {
        margin-bottom: map-get($spacers, 2);
      }
    }

    &__Item {
      color: $xc-typo-secondary;
    }

    &__Amount {
      color: $xc-typo-primary;
    }

    &--total {
      font-weight: 600;
    }
  }
}
