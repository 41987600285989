@import "../../bootstrap-theme.scss";

.Headered-Page {
  min-height: 100%;
  background-color: $xc-material-surface-primary-default;

  &__Header {
    height: 430px;
    background-color: $xc-brand-primary-dark;

    &__Logo {
      position: absolute;
      width: 43px;
      margin: map-get($spacers, 6);
    }

    &__Content {
      width: 100%;
      height: 430px;
      overflow: hidden;
    }
  }

  &__Content {
    width: 100%;
    max-width: calc(946px + map-get($spacers, 6) * 2);

    margin: auto;
    margin-top: calc(calc(400px - 120px) * -1);

    padding-left: map-get($spacers, 6);
    padding-right: map-get($spacers, 6);

    @include media-breakpoint-down(md) {
      margin-top: calc(calc(400px - 100px) * -1);
    }
  }
}
