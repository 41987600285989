@import "../../../bootstrap-theme.scss";

.Upload-Document-Modal {
  &__Title {
    font-weight: 800;
    font-size: 24px;
  }

  &__Tab-Choices {
    background-color: $xc-material-surface-primary-alternative;
    border-radius: $btn-border-radius;
    display: flex;

    .hstack {
      width: 100%;
    }

    .btn {
      width: 100%;
    }
  }
}
