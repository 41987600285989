@use "sass:color";
@import "../../../bootstrap-theme.scss";

.Overview-Section {
  padding-top: map-get($spacers, 5);

  &:not(:first-child) {
    border-top: 1px solid color.adjust($xc-material-border, $alpha: -0.5);
    margin-top: map-get($spacers, 5);
  }
}
