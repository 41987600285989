@import "../../../bootstrap-theme.scss";

.Acknowledgement-Section {
  background-color: $xc-material-primary-background;
  border-radius: $border-radius;
  padding: map-get($spacers, 8) calc(map-get($spacers, 8) * 2)
    map-get($spacers, 8) calc(map-get($spacers, 8) * 2);
  margin-bottom: map-get($spacers, 5);

  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  white-space: pre-line;

  @include media-breakpoint-down(md) {
    padding: map-get($spacers, 7) calc(map-get($spacers, 4) * 2)
      map-get($spacers, 7) calc(map-get($spacers, 4) * 2);
  }

  &__Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: map-get($spacers, 4);

    &--collapsed {
      margin-bottom: 0px;
    }

    .btn {
      height: 48px;
    }

    &__Title {
      color: $xc-typo-primary;
      font-size: 20px;
      font-weight: 800;
    }

    &__Subtitle {
      color: $xc-brand-secondary-dark;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      margin-bottom: map-get($spacers, 1);
    }
  }
}
