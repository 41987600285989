@import "../../../../../bootstrap-theme.scss";

.Member-Row {
  &__Roles {
    max-width: 115px;

    &__Role {
      font-size: 12px;
      font-weight: 600;
      color: $xc-state-info-dark;
    }
  }

  &__Name {
    display: flex;
    align-items: center;

    &--primary {
      font-size: 10px;
    }
  }
}
