@import "../../../bootstrap-theme.scss";

.Info-Card-Section {
  display: flex;

  &:not(:last-child) {
    padding-bottom: map-get($spacers, 4);
    border-bottom: 1px solid $border-color;
    margin-bottom: map-get($spacers, 4);
  }

  &__Title {
    font-size: 12px;
    font-weight: 500;
    color: $xc-typo-secondary;
    margin-bottom: map-get($spacers, 2);
  }

  &--row {
    flex-direction: row;

    &:not(:last-child) {
      padding-bottom: 0px;
      border-bottom: 0px;
      margin-bottom: map-get($spacers, 4);
    }

    .Info-Card-Section__Title {
      margin-top: map-get($spacers, 3);
      width: 200px;
    }

    .Info-Card-Section__Content {
      flex: 1;
    }
  }

  &--column {
    flex-direction: column;

    .Info-Card-Section__Content {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
