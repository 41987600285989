@import "../../../../bootstrap-theme.scss";

.Xchange-Invoice-Overview {
  &__Header {
    &__Name {
      font-size: 20px;
      font-weight: 800;
    }

    &__Info {
      display: flex;
      align-items: center;
      margin-top: map-get($spacers, 2);
      font-size: 12px;
      font-weight: 500;
      color: $xc-typo-secondary;

      .badge {
        font-size: 10px;
        font-weight: 600;
        padding: 3px 5px 3px 5px;
      }
    }
  }

  &__Documents {
    max-height: 250px;
    overflow-y: auto;
    border-radius: $border-radius / 2;
    border: 1px solid $border-color;
    padding: map-get($spacers, 2);
  }
}
