@import "../../../bootstrap-theme.scss";

.Breakdown-Section {
  border-radius: $input-border-radius;
  padding: map-get($spacers, 2);
  margin-right: map-get($spacers, 5);

  &__Title {
    font-size: 12px;
    font-weight: 700;
  }

  &__Value {
    font-size: 20px;
    font-weight: 500;
    margin-top: map-get($spacers, 1);

    &:first-child {
      margin-top: map-get($spacers, 2) !important;
    }

    &__Type {
      color: $xc-typo-reverse-secondary;
    }
  }

  &--highlight {
    min-width: 135px;
    background-color: $xc-brand-primary-default;
    margin-right: 0;
  }
}
