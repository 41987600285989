.Xchange-Splash-Screen {
  display: flex;
  align-items: center;
  justify-content: center;

  &__Mask {
    position: absolute;
    border-radius: 300px;
    width: 400px;
    height: 400px;

    border-color: white;
    border-style: solid;
    border-width: 0px;
    animation: remove-splash-screen-mask 0.35s ease-in;
  }

  &__Logo {
    width: 300px;
    position: relative;
    z-index: -1;
    opacity: 0;

    filter: invert(20%) sepia(9%) saturate(6007%) hue-rotate(211deg)
      brightness(95%) contrast(94%);
    animation: jump-spin 0.65s cubic-bezier(0.28, 1.05, 1, 0.99),
      easy-fade-out 0.75s linear;
  }
}

@keyframes remove-splash-screen-mask {
  0% {
    border-width: 200px;
  }
  100% {
    border-width: 0px;
  }
}

@keyframes jump-spin {
  0% {
    transform: rotate(-359deg);
    scale: 0.1;
  }
  100% {
    transform: rotate(0deg);
    scale: 1;
  }
}

@keyframes easy-fade-out {
  0% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
