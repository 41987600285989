@import "../../../../bootstrap-theme.scss";

.Invoice-Bill {
  background-color: $xc-material-surface-primary-alternative;
  padding: map-get($spacers, 5);
  border-radius: $btn-border-radius;

  &__Header {
    padding-bottom: map-get($spacers, 5);
    border-bottom: 1px solid $border-color;
    margin-bottom: map-get($spacers, 5);

    &__Title {
      font-size: 20px;
      font-weight: 800;
    }
  }

  &__Summary {
    border-top: 1px solid $border-color;
    padding-top: map-get($spacers, 5);
    padding-bottom: map-get($spacers, 5);

    .hstack {
      &:not(:last-child) {
        margin-bottom: map-get($spacers, 2);
      }
    }

    &__Item {
      color: $xc-typo-secondary;

      &__Description {
        color: $xc-typo-disabled;
        font-size: 12px;
        font-weight: 400;
      }
    }

    &__Amount {
      color: $xc-typo-primary;
    }

    &--total {
      font-weight: 600;
    }
  }

  &:not(:last-child) {
    margin-bottom: map-get($spacers, 3);
  }
}
