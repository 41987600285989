@import "../../../../../bootstrap-theme.scss";

.Product-Terms-Table {
  &__Icons {
    width: 40px;
  }

  &__Actions {
    width: 50px;
  }

  th {
    box-shadow: inset 0 0 0 9999px $xc-material-surface-primary-alternative !important;
  }
}
