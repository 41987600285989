@import "../../../../../bootstrap-theme.scss";

.Domain-Selector {
  background-color: white;
  width: 100%;
  height: 100%;
  border: 1px solid $xc-material-border;
  border-radius: 16px;
  padding: map-get($spacers, 5);
  display: flex;
  flex-direction: column;

  &__Filters {
    flex: 0;
    padding-bottom: map-get($spacers, 5);

    &__Domains {
      background-color: $xc-material-surface-primary-default;
      border-radius: $btn-border-radius;
      padding-top: map-get($spacers, 2);
      padding-left: map-get($spacers, 2);
    }
  }

  &__List {
    flex: 1;
    background-color: $xc-material-surface-primary-alternative;
    border-top: 1px solid $xc-material-border;
    border-bottom: 1px solid $xc-material-border;
    margin-left: calc(map-get($spacers, 5) * -1);
    width: calc(100% + map-get($spacers, 7));
    padding: map-get($spacers, 5);
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    gap: map-get($spacers, 5);

    &__Item {
      flex-grow: 0;
      flex-basis: calc(100% / 2 - map-get($spacers, 5) / 2 * (2 - 1));

      @media (min-width: map-get($grid-breakpoints, xl)) {
        flex-basis: calc(100% / 3 - map-get($spacers, 5) / 3 * (3 - 1));
      }

      @media (min-width: map-get($grid-breakpoints, xxxxl)) {
        flex-basis: calc(100% / 4 - map-get($spacers, 5) / 4 * (4 - 1));
      }
    }
  }

  &__Footer {
    flex: 0;
    padding-top: map-get($spacers, 5);
  }
}
