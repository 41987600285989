@import "../../bootstrap-theme.scss";

.Info-Card {
  flex: 1;
  border-radius: $btn-border-radius;
  border: 1px solid $border-color;
  padding: map-get($spacers, 5);

  &__Title {
    font-size: 16px;
    font-weight: 800;
    margin-bottom: map-get($spacers, 5);
  }
}
