@import "../../../bootstrap-theme.scss";

.Overview-Menu-Item {
  font-size: 15px;
  font-weight: 800;
  color: $xc-typo-secondary;

  padding: map-get($spacers, 2);
  border-radius: $btn-border-radius;

  margin-top: map-get($spacers, 2);
  margin-bottom: map-get($spacers, 2);

  &__Icon {
    width: 22px;
    line-height: 22px;
    display: inline;
    margin-right: map-get($spacers, 2);
    font-size: 16px;
  }

  &--clickable {
    &:hover {
      cursor: pointer;

      &:not(.Overview-Menu-Item--active) {
        background-color: $xc-material-surface-primary-alternative;
      }
    }
  }

  &--active {
    background-color: $xc-brand-secondary-tint-4a;
    color: $xc-brand-secondary-dark;
  }

  &--disabled {
    color: $xc-typo-disabled;
  }
}
