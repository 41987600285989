@import "../../bootstrap-theme.scss";

.Password-Strength-Bar {
  &__Background {
    width: 100%;
    height: 5px;
    border-radius: $border-radius;
    background-color: $xc-brand-primary-tint-12a;
  }

  &__Progress {
    height: 5px;
    border-radius: $border-radius;

    &--4 {
      width: 100%;
      background-color: $xc-state-success-default;
    }

    &--3 {
      width: 100%;
      background-color: $xc-state-warning-default;
    }

    &--2,
    &--1 {
      width: 100%;
      background-color: $xc-state-error-default;
    }
  }

  &__Info {
    color: $xc-typo-disabled;
    font-weight: 500;
    font-size: 12px;
    margin-top: map-get($spacers, 1);
    display: flex;
    justify-content: space-between;

    &__Verbal {
      &--4 {
        color: $xc-state-success-default;
      }

      &--3 {
        color: $xc-state-warning-default;
      }

      &--2,
      &--1 {
        color: $xc-state-error-default;
      }
    }
  }
}
