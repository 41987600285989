@import "../../bootstrap-theme.scss";

.Badge-Checkbox {
  background-color: $xc-material-primary-background !important;
  border: 1px solid $xc-material-border;
  color: $xc-typo-primary;
  border-radius: $btn-border-radius;
  margin-right: map-get($spacers, 2);
  margin-bottom: map-get($spacers, 2);
  padding: map-get($spacers, 2);
  transition: $btn-transition;

  &:hover {
    background-color: $xc-material-border !important;
    cursor: pointer;
  }

  &--checked {
    background-color: $xc-brand-primary-dark !important;
    border: 1px solid $xc-brand-primary-dark;
    color: $xc-typo-reverse-primary;

    &:hover {
      background-color: $xc-brand-primary-default !important;
      border: 1px solid $xc-brand-primary-default;
    }
  }
}
