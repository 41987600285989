@import "../../../../bootstrap-theme.scss";

.Xchange-Epic-Card {
  border-radius: $input-border-radius;

  &__Grip {
    background-color: $xc-material-surface-primary-default;
    border-radius: $input-border-radius;

    &:hover {
      background-color: $xc-material-secondary-surface-hover;
      cursor: grab;
    }
  }

  &__Number {
    border-radius: $input-border-radius;
    background-color: $xc-brand-primary-dark;
    color: $xc-typo-reverse-primary;
    font-weight: 600;
    text-align: center;
    width: 26px;
    line-height: 26px;
  }

  &__Title {
    font-size: 12px;
    font-weight: 700;
  }

  &__Description {
    color: $xc-typo-secondary;
    font-size: 12px;
    font-weight: 400;
  }

  &__Badge {
    color: $xc-state-info-dark;

    float: right;
  }

  &__Duration {
    font-weight: 700;
    font-size: 12px;
  }
}
