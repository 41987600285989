@import "../../../../../../../bootstrap-theme.scss";

.Bills-Selection-Form {
  &__List {
    max-height: 450px;
    overflow-y: auto;
    padding: map-get($spacers, 5);
    width: calc(100% + map-get($spacers, 7));
    margin-left: map-get($spacers, 5) * -1;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
  }
}
